/* CURRENTLY USED IN: index.jsx */

/* Root router */

/* Native React Imports */
import React from 'react'

/* React Router Dom Components */
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'

/* Context imports */
import AuthContext from 'context/AuthContext'

/* Material-UI core components */
import { CssBaseline } from '@material-ui/core'

/* Material-UI core style components */
import { ThemeProvider } from '@material-ui/core/styles'

/* UI components */
import { theme } from 'utils/UI/Theme'

/* Import of different type of routes */
import LoginRoute from 'utils/routing/LoginRoute'
import RootRoute from 'utils/routing/RootRoute'
import PublicRoute from 'utils/routing/PublicRoute'
import PrivateRoute from 'utils/routing/PrivateRoute'

/* Imports of pages used in Route components */
import DashboardPage from 'components/DashboardPage'
import LoginPage from 'components/LoginPage'
import NotFoundPage from 'components/NotFoundPage'

import { useIsAuthenticated, useMsal } from '@azure/msal-react'
// import axios from 'axios'
import { toast } from 'react-toastify'
import { axiosInstance } from 'axios/baseRequests'
import { loginRequest } from 'authConfig'

export default function AppRouter () {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  // Add a request interceptor
  axiosInstance.interceptors.request.use(async (config) => {
    const activeAccount = instance.getActiveAccount()
    if (!activeAccount) {
      throw Error('No active account! Verify a user has been signed in.')
    }
    const silentRequest = {
      scopes: ['User.Read'],
      account: activeAccount
    }
    try {
      const response = await instance.acquireTokenSilent(silentRequest)
      const bearer = `Bearer ${response.idToken}`
      config.headers.Authorization = bearer
      return config
    } catch (error) {
      toast.error(
        'Error con token de autentificación, por favor vuelva a iniciar sesión.',
        {
          position: toast.POSITION.BOTTOM_RIGHT
        }
      )
      return await instance.loginRedirect(...loginRequest)
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContext.Provider value={{ isAuthenticated }}>
        <Router>
          <Switch>
            <LoginRoute path='/login' component={LoginPage} />
            <PrivateRoute path='/dashboard' component={DashboardPage} />
            <PublicRoute exact path='/not-found' component={NotFoundPage} />
            <RootRoute exact path='/' />
            <Redirect to='/not-found' />
          </Switch>
        </Router>
      </AuthContext.Provider>
    </ThemeProvider>
  )
}
