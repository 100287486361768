import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import 'bootstrap/dist/css/bootstrap.css'
import Spinner from 'react-bootstrap/Spinner'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    height: 'inherit',
    maxHeight: 200,
    maxWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignContent: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    textAlign: 'center'
  }
}))

const LoadingSpinner = (props) => {
  const classes = useStyles()
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={props.headerLength.toString()} className={classes.tableCell}>
          <Spinner animation='border' />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
export default LoadingSpinner
