import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#516282'
    },
    secondary: {
      main: '#fff'
    },
    text: {
      primary: '#707070'
    },
    error: {
      main: '#ed4337'
    },
    info: {
      main: '#67a3cb'
    }
  },
  typography: {
    fontFamily: 'Roboto, Sans-serif',
    h1: {
      fontSize: 24,
      fontWeight: 700,
      color: '#707070'
    },
    h2: {
      fontSize: 20,
      fontWeight: 400,
      color: '#707070'
    },
    body1: {
      fontSize: 18,
      fontWeight: 300,
      color: '#707070'
    },
    button: {
      fontSize: '1rem',
      fontWeight: 300,
      color: '#707070'
    },
    a: {
      fontSize: '1rem',
      fontWeight: 300,
      color: '#707070'
    }
  }
})
