/* Native React Imports */
import React from 'react'

/* React Router Dom Components */
import { Route, Redirect } from 'react-router-dom'
import { useAccount } from '@azure/msal-react'

const DirectorRoute = ({ component: Component, ...rest }) => {
  const account = useAccount()
  return (
    <Route
      render={() => (
        account && account.idTokenClaims.roles[0] === 'director'
          ? <Component {...rest} />
          : <Redirect to='/not-found' />
      )}
    />
  )
}

export default DirectorRoute
