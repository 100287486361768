import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'

import { IconButton, TextField, Container } from '@material-ui/core'
import { Search, Refresh, Clear } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  form: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'baseline',
    justifyContent: 'stretch',
    flexDirection: 'row',
    margin: 10
  },
  selector: {
    width: '33%',
    margin: 5
  }
}))

export const FilterSelectorsLogs = ({
  setFilterParams,
  fetchRecords
}) => {
  const classes = useStyles()
  const [date, setDate] = useState('')
  const [orderId, setOrderId] = useState('')
  const [portfolio, setPortfolio] = useState('')

  const handleFilterSubmit = () => {
    const params = {
      date: date,
      codigo_ord: orderId,
      fondo: portfolio
    }
    sessionStorage.setItem('logsFilters', JSON.stringify(params))
    setFilterParams(params)
  }

  const handleCleanFilters = () => {
    sessionStorage.removeItem('logsFilters')
    setDate('')
    setOrderId('')
    setPortfolio('')
    setFilterParams()
  }

  useEffect(() => {
    const filters = JSON.parse(sessionStorage.getItem('logsFilters'))
    if (filters) {
      setDate(filters.date)
      setOrderId(filters.codigo_ord)
      setPortfolio(filters.fondo)
    }
  }, [])

  return (
    <Container maxWidth={false} className={classes.container}>
      <IconButton color='primary' onClick={() => fetchRecords()}>
        <Refresh />
      </IconButton>
      <form className={classes.form}>

        <TextField
          id='outlined-fecha-input'
          className={classes.selector}
          label='Fecha (AAAA-MM-DD)'
          variant='outlined'
          value={date}
          onChange={(event) => setDate(event.target.value)}
          error={date !== '' && !(moment(date, 'YYYY-MM-DD', true).isValid())}
        />
        <TextField
          id='outlined-order-id-input'
          className={classes.selector}
          label='Código Orden'
          variant='outlined'
          value={orderId}
          onChange={(event) => setOrderId(event.target.value)}
        />
        <TextField
          id='outlined-portfolio-input'
          className={classes.selector}
          label='Fondo'
          variant='outlined'
          value={portfolio}
          onChange={(event) => setPortfolio(event.target.value)}
        />
      </form>
      <IconButton color='primary' onClick={() => handleFilterSubmit()}>
        <Search />
      </IconButton>
      <IconButton color='primary' onClick={() => handleCleanFilters()}>
        <Clear />
      </IconButton>
    </Container>
  )
}
