/* Native React Imports */
import React, { useContext } from 'react'

/* React Router Dom Components */
import { Route, Redirect } from 'react-router-dom'

/* Context providers */
import AuthContext from 'context/AuthContext'

const LoginRoute = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated } = useContext(AuthContext)
  return (
    <Route
      render={() => (
        isAuthenticated ? (<Redirect to='/dashboard/orders' />) : (<Component {...rest} />)
      )}
    />
  )
}

export { LoginRoute as default }
