import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflowX: 'hidden'
  },
  dialogTitle: {
    backgroundColor: 'white'
  },
  dialogContent: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10
  },
  container: {
    maxHeight: 600
  },
  table: {
    size: 'small'
  },
  tableCell: {
    size: 'small'
  },
  toolTip: {
    fontSize: '16',
    variant: 'h1'
  },
  typo: {
    variant: 'p'
  },
  downloadButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5
  },
  clampText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const limitCols = [
  {
    id: 'limit_rd_id',
    label: 'ID Limite',
    align: 'left'
  },
  {
    id: 'descriptive_type',
    label: 'Tipo Descriptivo',
    align: 'left'
  },
  {
    id: 'lim_inf',
    label: 'Lim. Inf',
    format: (value) => Number(value).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }),
    align: 'right'
  },
  {
    id: 'lim_sup',
    label: 'Lim. Sup',
    format: (value) => Number(value).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }),
    align: 'right'
  },
  {
    id: 'weight_1',
    label: 'Calculo Inicial',
    format: (value) => Number(value).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }),
    align: 'right'
  },
  {
    id: 'weight_2',
    label: 'Calculo Final',
    format: (value) => Number(value).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 }),
    align: 'right'
  },
  {
    id: 'bloqueante',
    label: 'Bloqueante',
    format: (value) => value === 1 ? 'Si' : 'No',
    align: 'left'
  }
]

const collapsibleLimitCols = [
  {
    id: 'glosa',
    label: 'Glosa',
    maxHeight: 25,
    align: 'left'
  },
  {
    id: 'desc_limite',
    label: 'Descripción',
    maxHeight: 25,
    align: 'left'
  }
]

const OrderModal = ({ open, handleClose, limits, processedOrder }) => {
  const classes = useStyles()
  const [openRows, setOpenRows] = useState({})

  const handleRowClick = (id) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id]
    }))
  }
  const handleCloseAndReset = () => {
    setOpenRows({})
    handleClose()
  }
  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={handleCloseAndReset}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant='h1' align='center'>
            Bitácora de la Orden {processedOrder ? processedOrder.codigo_ord : null}
          </Typography>
        </DialogTitle>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell />
                {limitCols.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    <Typography variant='h6'>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {limits && limits.map((limit) => (
                <React.Fragment key={limit.limit_rd_id}>
                  <TableRow
                    tabIndex={-1}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => handleRowClick(limit.limit_rd_id)}
                      >
                        {openRows[limit.limit_rd_id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    {limitCols.map((column) => {
                      const value = limit[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={limitCols.length + 1}>
                      <Collapse in={openRows[limit.limit_rd_id]} timeout='auto' unmountOnExit>
                        <Table size='small' aria-label='purchases'>
                          <TableBody>
                            {collapsibleLimitCols.map((column) => {
                              const value = limit[column.id]
                              return (
                                <TableRow key={column.id}>
                                  <TableCell align={column.align}>
                                    {column.label}
                                  </TableCell>
                                  <TableCell align={column.align}>
                                    {column.format ? column.format(value) : value}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

export default OrderModal
