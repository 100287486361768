import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { IconButton } from '@material-ui/core'
import { Refresh, CheckCircleOutline, ErrorOutline } from '@material-ui/icons'
import { Typography } from '@material-ui/core/index'

import { healthChecksRequest } from 'axios/requests/healthChecks'

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  tableCellContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center'
  },
  flexItem: {
    alignContent: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableRow: {
    height: 60,
    border: '1px solid'
  },
  tableCell: {
    padding: 0,
    height: 'inherit',
    maxHeight: 200,
    maxWidth: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    alignContent: 'left',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  spacedIcon: {
    marginLeft: 5
  }
})

const Status = () => {
  const classes = useStyles()
  const [backEndStatus, setbackEndStatus] = useState(false)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getBackEndStatus()
    }
    return () => { mounted = false }
  }, [])

  const getBackEndStatus = async () => {
    try {
      const result = await healthChecksRequest()
      if (result) {
        setbackEndStatus(true)
      }
    } catch (err) {
    }
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.TableContainer}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>Resource</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <IconButton color='primary' justifyContent='flex-start' onClick={() => getBackEndStatus()}>
                  <Refresh />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell> <Typography>PTC Back-End </Typography></TableCell>
              <TableCell className={classes.tableCellContainer}>
                {backEndStatus
                  ? (
                    <Typography className={classes.flexItem} style={{ color: 'lightgreen' }}>
                      <> Available </>
                      <CheckCircleOutline className={classes.spacedIcon} />
                    </Typography>)
                  : (
                    <Typography className={classes.flexItem} style={{ color: 'red' }}>
                      <> Unavailable </>
                      <ErrorOutline className={classes.spacedIcon} />
                    </Typography>)}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default Status
