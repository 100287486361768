import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const NoData = () => {
  return (
    <TableRow>
      <TableCell colSpan={12}>No hay datos disponibles</TableCell>
    </TableRow>
  )
}

export default NoData
