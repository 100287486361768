import React from 'react'

import {
  Container,
  Box,
  Typography,
  Paper
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import MsLogin from 'components/MsLogin'

import logo from 'assets/images/logo.png'

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '50%'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 500,
    maxHeight: 500,
    padding: theme.spacing(1)
  },
  loginHeader: {
    margin: 10

  },
  microsoftBtn: {
    marginTop: 50,
    marginBottom: 50
  }
}))

const LoginPage = () => {
  const classes = useStyles()

  return (
    <Container maxWidth={false} className={classes.container}>
      <Box className={classes.main} component={Paper}>
        <img
          src={logo}
          className={classes.logo}
        />
        <Typography
          className={classes.loginHeader} variant='h1' gutterBottom
        > Pre-Trade Compliance
        </Typography>
        <Typography
          className={classes.loginHeader} variant='h2' gutterBottom
        > Bienvenido, inicia sesión en tu cuenta
        </Typography>
        <div className={classes.microsoftBtn}>
          <MsLogin />
        </div>
      </Box>
    </Container>

  )
}

export default LoginPage
