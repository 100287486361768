/* Native React Imports */
import React, { useContext } from 'react'

/* React Router Dom Components */
import { Route, Redirect } from 'react-router-dom'

/* Context providers */
import AuthContext from 'context/AuthContext'

const RootRoute = () => {
  const { isAuthenticated } = useContext(AuthContext)
  return (
    <Route
      render={() => (
        isAuthenticated ? (<Redirect to='/dashboard/orders' />) : (<Redirect to='/login' />)
      )}
    />
  )
}

export default RootRoute
