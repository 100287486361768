import React from 'react'

import {
  Box,
  Container,
  Button,
  AppBar,
  Toolbar
} from '@material-ui/core'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { makeStyles } from '@material-ui/core/styles'

import logo from 'assets/images/logo.png'

import DashboardRouter from 'routers/DashboardRouter'
import { Link } from 'react-router-dom'
import { useMsal, useAccount } from '@azure/msal-react'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: 0,
    padding: 0
  },
  appBar: {
    backgroundColor: theme.palette.secondary.main,
    position: 'relative'
  },
  toolbar: {
    display: 'flex',
    flexGrow: 1,
    maxHeight: 25,
    justifyContent: 'space-between'
  },
  logo: {
    maxHeight: '100%',
    width: '10%'
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black'
  },
  btnGroupChild: {
    color: theme.typography.button.color,
    margin: theme.spacing(2),
    textDecoration: 'none'
  },
  main: {
    display: 'flex'
  }
}))

const DashboardPage = () => {
  const classes = useStyles()
  const { instance } = useMsal()
  const currentAccount = useAccount(instance.getActiveAccount())

  const handleLogout = async (instance) => {
    await instance.logoutRedirect({ account: currentAccount }).catch(e => {
      console.error(e)
    })
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img
            src={logo}
            className={classes.logo}
          />
          <Box className={classes.btnGroup}>
            <Link className={classes.btnGroupChild} to='/dashboard/orders'>Ordenes</Link>
            {currentAccount && currentAccount.idTokenClaims.roles[0] === 'director'
              ? <Link className={classes.btnGroupChild} to='/dashboard/logs'>Bitácora</Link>
              : null}
            <Link className={classes.btnGroupChild} to='/dashboard/status'>Status</Link>
            <Button
              className={classes.btnGroupChild} color='secondary' onClick={() => handleLogout(instance)}
            ><ExitToAppIcon />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <DashboardRouter />
      </div>
    </Container>

  )
}

export default DashboardPage
