import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { Box } from '@material-ui/core'
import { Button } from '../../node_modules/@material-ui/core/index'

import { recordsRequest } from 'axios/requests/records'
import { recordsDownloadRequest } from 'axios/requests/recordsDownload'
import LoadingSpinner from 'components/LoadingSpinner'
import { FilterSelectorsLogs } from 'components/FilterSelectorsLogs'

import NoData from 'utils/UI/NoData/NoData'

const columns = [
  {
    id: 'date',
    label: 'Fecha',
    format: (value) => {
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    colWidth: '20%',
    align: 'left'

  },
  {
    id: 'fondo',
    label: 'Fondo',
    colWidth: '15%',
    align: 'left'
  },
  {
    id: 'codigo_ord',
    label: 'ID Orden',
    colWidth: '15%',
    align: 'left'
  },
  {
    id: 'message',
    label: 'Mensaje',
    colWidth: '50%',
    toolTip: true,
    align: 'left'
  }
]

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: '70vh'
  },
  tableRow: {
    height: 60,
    border: '1px solid'
  },
  tableCell: {
    height: 'inherit',
    maxHeight: 200,
    maxWidth: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    alignContent: 'left',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  downloadButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
})

const Logs = () => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [totalRecords, setTotalRecords] = useState(0)
  const [records, setRecords] = useState([])
  const filters = JSON.parse(sessionStorage.getItem('logsFilters'))
  const [filterParams, setFilterParams] = useState(filters || { estado: 'Pre-anulada' })
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')

  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchRecords()
    }
    return () => { mounted = false }
  }, [filterParams])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    fetchRecords(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(+event.target.value)
    fetchRecords(0, +event.target.value)
  }

  const fetchRecords = async (currentPage = page, pageSize = rowsPerPage) => {
    try {
      setLoading(true)
      const result = await recordsRequest(
        {
          ...filterParams,
          page: 1 + currentPage,
          page_size: pageSize
        }
      )
      setLoading(false)
      const newRecords = result.data.results
      setRecords(newRecords)
      setTotalRecords(result.data.count)
    } catch (err) {
      console.log(err)
    }
  }

  const handleRecordsDownload = async () => {
    try {
      const result = await recordsDownloadRequest({ ...filterParams })
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Bitacoras.xlsx'
      )
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      console.log(err)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <Paper className={classes.root}>
      <Box className={classes.downloadButtonContainer}>
        <Button
          color='primary'
          type='button'
          variant='contained'
          onClick={() => handleRecordsDownload()}
        >
          Descargar Bitácoras
        </Button>
      </Box>
      <FilterSelectorsLogs
        setFilterParams={setFilterParams}
        fetchRecords={fetchRecords}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {loading && <LoadingSpinner headerLength={columns.length + 1} />}
          <TableBody>
            {
              records.length > 0
                ? (records.slice().sort(getComparator(order, orderBy)).map((row, index) => (
                  <TableRow
                    hover
                    role='checkbox'
                    key={row.id.toString()}
                  >
                    {columns.map((column, index2) => {
                      const value = row[column.id]
                      if (column.toolTip) {
                        return (
                          <Tooltip
                            title={<h4>{value}</h4>}
                            placement='top'
                            enterDelay={1000}
                            enterNextDelay={500}
                            leaveTouchDelay={0}
                          >
                            <TableCell
                              key={row.id.toString().concat(index2)}
                              align={column.align}
                              className={classes.tableCell}
                            >
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          </Tooltip>
                        )
                      } else {
                        return (
                          <TableCell
                            key={row.id.toString().concat(index2)}
                            align={column.align}
                            className={classes.tableCell}
                          >
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                  )))
                : (!loading && <NoData />)
        }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component='div'
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort
  } = props
  const classes = useStyles()
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default Logs
