import React from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../authConfig'
import { Button } from '@material-ui/core'

const handleLogin = async (instance) => {
  await instance.loginRedirect(loginRequest).catch((error) => {
    console.error(error)
  })
}

const MsLogin = () => {
  const { instance } = useMsal()

  return (
    <Button variant='contained' className='ml-auto' onClick={() => handleLogin(instance)}>
      Sign in
    </Button>
  )
}

export default MsLogin
