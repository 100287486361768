/* Native React Imports */
import React from 'react'

/* React Router Dom Components */
import { Route } from 'react-router-dom'

const PublicRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      render={() => (<Component {...rest} />)}
    />
  )
}

export { PublicRoute as default }
