import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 120000
})

const authGetRequest = async (path, headers, params, customResponseType = null) => {
  return axiosInstance({
    method: 'get',
    url: path,
    headers: {
      ...headers
    },
    responseType: customResponseType || 'json',
    params: { ...params }
  })
}

const authPostRequest = async (path, headers, data, customResponseType = null) => {
  return axiosInstance({
    method: 'post',
    url: path,
    headers: {
      ...headers
    },
    responseType: customResponseType || 'json',
    data: { ...data }
  })
}

export { authGetRequest, authPostRequest, axiosInstance }
