import React from 'react'

import { Link } from 'react-router-dom'

import { Box, Typography } from '@material-ui/core'

const NotFoundPage = () => {
  return (
    <Box>
      <Typography variant='body1'>Not Found</Typography>
      <Link to='/'>Ir al inicio</Link>
    </Box>

  )
}

export default NotFoundPage
