import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button
} from '@material-ui/core'
import { Typography } from '../../node_modules/@material-ui/core/index'

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflow: 'hidden'
  },
  modal: {
    overflow: 'hidden'
  },
  dialogTitle: {
    backgroundColor: 'white',
    marginLeft: 26
  },
  dialogContent: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    width: 600
  }
}))

const StyledDialog = withStyles((theme) => ({
  paper: {
    overflow: 'hidden'
  }
}))(Dialog)

const Popup = ({
  openJustificationDialog,
  setOpenJustificationDialog,
  handleRequestSubmit,
  order,
  action
}) => {
  const classes = useStyles()

  const handleSubmit = () => {
    handleRequestSubmit()
    setOpenJustificationDialog(false)
  }

  const handleCancel = () => {
    setOpenJustificationDialog(false)
  }

  return (

    <StyledDialog
      key='simple-dialog-for-justification-po-up'
      open={openJustificationDialog}
      onClose={handleCancel}
      className={classes.modal}
      scroll='body'
    >
      <DialogTitle className={classes.dialogTitle}>
        {order
          ? (
            <>
              <Typography variant='h1' align='justify'>
                ¿Estas seguro que quieres {action} la orden con ID {order.codigo_ord}?
              </Typography>
            </>)
          : null}

      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers={false}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            color='primary'
            type='button'
            variant='contained'
            className={classes.button}
            onClick={() => handleCancel()}
          >
            No
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={() => handleSubmit()}
          >
            Si
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>

  )
}

export default Popup
