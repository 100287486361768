/* CURRENTLY USED IN: index.jsx */

/* Root router */

/* Native React Imports */
import React from 'react'

/* React Router Dom Components */
import { Route, Redirect, Switch } from 'react-router-dom'

/* Imports of pages used in Route components */
import Logs from 'components/Logs'
import Orders from 'components/Orders'
import Status from 'components/Status'

import DirectorRoute from '../utils/routing/DirectorRoute'

export default function AppRouter () {
  return (
    <Switch>
      <Route exact path='/dashboard/orders' component={Orders} />
      <Route path='/dashboard/orders/:orderIdParam' component={Orders} />
      <DirectorRoute exact path='/dashboard/logs' component={Logs} />
      <DirectorRoute exact path='/dashboard/status' component={Status} />
      <Redirect to='/not-found' />
    </Switch>
  )
}
